/* reset css */
a:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: none;
}

body,
html {
  min-height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

fieldset,
img,
abbr,
acronym {
  border: 0 none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

img,
video {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  image-rendering: -moz-auto;
  /*firefox*/
  image-rendering: -o-auto;
  /*opera*/
  image-rendering: -webkit-optimize-contrast;
  /*webkit*/
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  /* -webkit-perspective: 1; */
  -webkit-tap-highlight-color: transparent;
}

ul,
ol,
dl,
li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

i {
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select,
label {
  vertical-align: middle;
}

button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
  font-weight: normal;
  letter-spacing: normal;
  background: none;
}

button,
input {
  line-height: normal;
  box-shadow: none;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: none;
}

address {
  font-style: normal;
}

/* ::-webkit-scrollbar { width: 6px; height: 6px; } 
  ::-webkit-scrollbar-track { background: transparent; } 
  ::-webkit-scrollbar-thumb { background: #888; border-radius: 999px; background-clip: padding-box; border-top: 7px solid transparent; border-bottom: 10px solid transparent; border-right: 2px solid transparent; border-left: 2px solid transparent; } 
  */

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
}

input::-webkit-date-and-time-value {
  margin-top: 0px;
  text-align: left;
  width: 100%;
}

/* common style */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

@font-face {
  font-family: "SUIT";
  font-weight: 100;
  src: url("./resources/font/SUITv1-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 200;
  src: url("./resources/font/SUITv1-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 300;
  src: url("./resources/font/SUITv1-Light.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 400;
  src: url("./resources/font/SUITv1-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 500;
  src: url("./resources/font/SUITv1-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 600;
  src: url("./resources/font/SUITv1-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 700;
  src: url("./resources/font/SUITv1-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 800;
  src: url("./resources/font/SUITv1-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "SUIT";
  font-weight: 900;
  src: url("./resources/font/SUITv1-Heavy.woff2") format("woff2");
}

:root {
  --main: #ea5504;
  --black: #3c3c3c;
  --gray01: #b3b8c2;
  --gray02: #e2e4e9;
  --gray03: #f2f3f6;
  --gray04: #9198a7;
  --red: #ff0000;
}

body {
  font-family: "SUIT", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html.hidden {
  overflow: hidden;
}
body.hidden {
  height: auto;
  overflow: hidden;
}

button,
input,
textarea,
select {
  font-family: "SUIT", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  letter-spacing: -0.02em;
}

input::placeholder,
textarea::placeholder {
  color: var(--gray01);
}

@font-face {
  font-family: "KimjungchulScript-Light";
  src: url("./resources/font/KimjungchulScript-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.mbOnly {
  display: none;
}

.inner {
  position: relative;
  width: 1640px;
  margin: 0 auto;
}

.narrowInner {
  width: 1330px;
}

.formInner {
  width: 530px;
  margin: 0 auto;
}

.topButton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 67px;
  height: 67px;
  z-index: 4;
}

@media (max-width: 1740px) {
  body {
    font-size: calc(100vw * (20 / 1740));
  }

  button,
  input,
  textarea,
  select {
    font-size: calc(100vw * (16 / 1740));
  }

  .inner {
    width: calc(100vw * (1640 / 1740));
  }

  .narrowInner {
    width: calc(100vw * (1330 / 1740));
  }

  .formInner {
    width: calc(100vw * (530 / 1740));
  }

  .topButton {
    bottom: calc(100vw * (50 / 1740));
    right: calc(100vw * (50 / 1740));
    width: calc(100vw * (67 / 1740));
    height: calc(100vw * (67 / 1740));
  }
}

@media (max-width: 768px) {
  body {
    font-size: calc(100vw * (16 / 375));
  }

  button,
  input,
  textarea,
  select {
    font-size: calc(100vw * (16 / 375));
  }

  .pcOnly {
    display: none;
  }

  .mbOnly {
    display: block;
  }

  .inner {
    width: calc(100vw * (335 / 375));
  }

  .narrowInner {
    width: calc(100vw * (335 / 375));
    margin: 0 auto;
  }

  .formInner {
    width: calc(100vw * (335 / 375));
  }

  .topButton {
    bottom: calc(100vw * (20 / 375));
    right: calc(100vw * (20 / 375));
    width: calc(100vw * (67 / 375));
    height: calc(100vw * (67 / 375));
  }
}
